import { Container } from "reactstrap";

type PhotoCardProps = {
  url: string | undefined;
};
export function PhotoCard(props: PhotoCardProps) {
  const { url } = props;
  //TO DO how to have photo card and Inspection card always have the same size
  return (
    <Container
      className=""
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "40vh",
      }}
    >
      <img src={url} alt="No Photo Available" />
    </Container>
  );
}
