import { useEffect, useState } from "react";
import { Input, Container, Button, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { authService } from "src/services/AuthService";
import logo from "src/assets/img/logo.png";
import useLocalStorageState from "use-local-storage-state";
import { InvalidPasswordException, NotAuthorizedException, PasswordResetRequiredException } from "@aws-sdk/client-cognito-identity-provider";
export default function LoginPage() {
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [refreshToken, setRefreshToken] = useLocalStorageState<string>("refresh", {defaultValue: ""});
  
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordMessage, setPasswordMessage] = useState<string>("");

  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [resetCode, setResetCode] = useState<string>("");
  
  const navigate = useNavigate();
  useEffect (()=>{
    if(refreshToken !== ""){
      authService.refresh(refreshToken).then((result)=>{
        navigate("/dashboard", { replace: true });
      }).catch((e)=>{
        //probably just expired token, possibly change this later to handle other errors
        setRefreshToken("");
        setIsHidden(false);
      });
    }
    else{
      setIsHidden(false);
    }
  }, [])

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      let result;
      setUsernameError(false);
      setPasswordError(false);
      if(newPassword){
        result = await authService.newPassword(username, password);
        setNewPassword(false);
      }
      else if(isResetPassword){
        result = await authService.resetPassword(username, password, resetCode);
        if(result){
          console.log("succeeded in resetting, refresh page");
          setIsResetPassword(false);
          setResetCode("");
          setPasswordError(true);
          setPasswordMessage("Password Reset Succeeded, please sign in again");
          return;
        }
      }
      else{
        result = await authService.signIn(username, password);
      }
      if (result) {
        let token: string = result.RefreshToken !== undefined ? result.RefreshToken : "";
        setRefreshToken(token);
        console.log("Sign in successful, Navigating to Dashboard");
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      if(error === "NEW_PASSWORD_REQUIRED"){
        setPasswordError(true);
        setNewPassword(true);
        setPasswordMessage("Please Enter a New Password");
        setPassword("");
      }
      else{
        //TODO: this could be handled better, enhance in the future
        //actually split the branches properly, or something
        if(error instanceof InvalidPasswordException || error instanceof NotAuthorizedException){
          //lazy error handling, if the issue could be user or password (not authorized exception) show username as errored, otherwise just show password
          if(error instanceof NotAuthorizedException) setUsernameError(true);
          setPasswordError(true);
          //show error message
          setPasswordMessage(error.message);
        }
        else if(error instanceof PasswordResetRequiredException){
          console.log("password reset required");
          setIsResetPassword(true);
          setPasswordError(true);
          setPasswordMessage("Password reset required, please enter code and new password");
        }
        else{
          alert(`Sign in failed: ${error}`);
        }
      }
    }
  };

  const handleSignUp = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      await authService.signUp(username, email, password);
      navigate("/confirm", { state: { email } });
    } catch (error) {
      alert(`Sign up failed: ${error}`);
    }
  };

  return (
    <div hidden={isHidden}>
      <h1 className="text-center" style={{ backgroundColor: "#ffd200" }}>
        Prince George's County Snow Dashboard
      </h1>
      <Container
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={logo} alt="Welcome!" />
        <h4 className="">
          {/*   {isSignUp
            ? "Sign up to create an account"
            : "Sign in to your account"} */}
          {newPassword ? "Please Enter a New Password" : isResetPassword ? "Password reset required, please enter new password and reset code" : "Sign in to your account"}
        </h4>
        <form
          style={{
            maxWidth: "500px",
            width: "100%",
          }}
          onSubmit={isSignUp ? handleSignUp : handleSignIn}
        >
          <div>
            <Input
              className="inputText"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              invalid={usernameError}
              required
            />
          </div>

          {isSignUp && (
            <div>
              <Input
                className="inputText"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
          )}
          {isResetPassword && (
            <div>
              <Input
                className="inputText"
                id="resetCode"
                invalid={isResetPassword}
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                placeholder="Password Reset Code"
                />
            </div>
          )}
          <div>
            <Input
              className="inputText"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              invalid={passwordError}
              required
            />
            {passwordError && (<FormFeedback>{passwordMessage}</FormFeedback>)}
          </div>
          {isSignUp && (
            <div>
              <Input
                className="inputText"
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                required
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5px",
            }}
          >
            <Button type="submit">{isSignUp ? "Sign Up" : "Sign In"}</Button>
          </div>
        </form>
        {/*      <button onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp
            ? "Already have an account? Sign In"
            : "Need an account? Sign Up"}
        </button> */}
      </Container>
      <br />
    </div>
  );
}
