import { Card, CardHeader, Button, CardBody } from "reactstrap";
import { useViewModal } from "src/contexts/ViewModalContext";
import { millisecondsToHours, millisecondsToMinutes } from "src/utils/convert";
import { AddAssignment } from "./AddAssignment";
import { useEffect, useState } from "react";

type DisplayAssignmentProps = {
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DisplayAssignment(props: DisplayAssignmentProps) {
  const { setEditToggle } = props;
  const viewModalContext = useViewModal();
  const { fullRecord } = viewModalContext;
  const { assignment } = fullRecord;
  const [duration, setDuration] = useState<number>(-1);

  useEffect(() => {
    if (assignment != null) {
      if (assignment.time_in != null && assignment.time_out != null) {
        let timeDiff: number =
          assignment.time_out.getTime() - assignment.time_in.getTime();
        setDuration(timeDiff);
      }
    } else setDuration(0);
  }, [assignment]);
  console.log(assignment);

  return assignment === null ? (
    <AddAssignment setEditToggle={setEditToggle} />
  ) : (
    <Card>
      <CardHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Assignment Report
        <div>
          <Button color="link" onClick={() => setEditToggle((prev) => !prev)}>
            Edit
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{}}>
            <div>Driver Full Name: {assignment.driver_name}</div>
            <div>Phone Number: {assignment.driver_phone}</div>
            <div>Shift: {assignment.shift_type}</div>
            <div>District: {assignment.district}</div>
          </div>
          <div style={{}}>
            <div>Route: {assignment.route}</div>
            <div>
              Check-In:{" "}
              {assignment.time_in === null
                ? ""
                : assignment.time_in.toLocaleString("en-US", {
                    timeZone: "UTC",
                  })}
            </div>
            <div>
              Check-Out:{" "}
              {assignment.time_out === null
                ? ""
                : assignment.time_out.toLocaleString("en-US", {
                    timeZone: "UTC",
                  })}
            </div>
            <div>
              {typeof duration === "number" && duration >= 0 ? (
                <>
                  Duration: {millisecondsToHours(duration)} Hours and{" "}
                  {millisecondsToMinutes(duration)} Minutes{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
