import { Dispatch, SetStateAction } from "react";
import pgcLogo from "../../../../assets/img/logo.png";
import settingsSVG from "../../../../assets/img/settings.svg";
import dashboardSVG from "../../../../assets/img/dashboard.svg";
import usersSVG from "../../../../assets/img/users.svg";
import logoutSVG from "../../../../assets/img/avatar.svg";
import burgerSVG from "../../../../assets/img/burger.svg";
import arrowSVG from "../../../../assets/img/arrow_left.svg";
import { Link, useNavigate } from "react-router-dom";
// import burgerSVG from "@assets/img/burger.svg";
import { authService } from "src/services/AuthService";
import useLocalStorageState from "use-local-storage-state";

interface HeaderProps {
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
}

export default function Header(headerProps: HeaderProps) {
  const { showSideBar } = headerProps;
  const navigate = useNavigate();
  const [refreshToken, setRefreshToken] = useLocalStorageState<string>("refresh", {defaultValue: ""});

  async function handleLogout() {
    setRefreshToken(""); 
    await authService.signOut();
    navigate("/login");
  }

  return (
    <header>
      <div className="fl">
        {showSideBar ? (
          <div className="column dark cent">
            <img src={pgcLogo} className="logo mx" alt="logotype" />
          </div>
        ) : (
          <></>
        )}

        <nav className="content va spb">
          <div className="menu-left va">
            <div className="menu-block ul-block">
              <button
                className="menu-item burger arrow-left"
                onClick={() =>
                  headerProps.setShowSideBar((prevState) => !prevState)
                }
              >
                {headerProps.showSideBar ? (
                  <img
                    src={arrowSVG}
                    className="cls-1 svg header-icon"
                    alt="arrow"
                  />
                ) : (
                  <img
                    src={burgerSVG}
                    className="cls-1 svg header-icon"
                    alt="burger"
                  />
                )}
              </button>
            </div>
            <Link to="dashboard" className="menu-item tc">
              <div className="item-block">
                <div className="menu-icon">
                  <img
                    src={dashboardSVG}
                    className="cls-1 svg header-icon"
                    alt="dashboard"
                  />
                </div>
                <p>
                  <strong>Dashboard</strong>
                </p>
              </div>
            </Link>
            <Link to="users" className="menu-item tc">
              <div className="item-block">
                <div className="menu-icon">
                  <img
                    src={usersSVG}
                    className="cls-1 svg header-icon"
                    alt="users"
                  />
                </div>
                <p>
                  <strong>Users</strong>
                </p>
              </div>
            </Link>
            <Link to="settings" className="menu-item tc">
              <div className="item-block">
                <div className="menu-icon">
                  <img
                    src={settingsSVG}
                    className="cls-1 svg header-icon"
                    alt="settings"
                  />
                </div>
                <p>
                  <strong>Settings</strong>
                </p>
              </div>
            </Link>
          </div>
          <Link to="login" className="menu-item tc" onClick={()=>{handleLogout();}}>
            <div className="item-block">
              <div className="menu-icon">
                <img
                  src={logoutSVG}
                  className="cls-1 svg header-icon"
                  alt="settings"
                />
              </div>
              <p>
                <strong>log out</strong>
              </p>
            </div>
          </Link>
        </nav>
      </div>
    </header>
  );
}
