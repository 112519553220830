export type Assignment = {
  shift_type: string;
  district: string;
  driver_name: string;
  driver_phone: string;
  route: string;
  time_out: Date | null;
  time_in: Date | null;
  inspection_id: number;
  id?: number;
};

//TO DO remove the temp values and fix the bigger problem in assignment
export const blankAssignment = {
  id: 0,
  inspection_id: 0,
  shift_type: "",
  district: "",
  driver_name: "",
  driver_phone: "",
  route: "",
  time_out: null,
  time_in: null,
};
