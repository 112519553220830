export function millisecondsToHours(milliseconds: number) {
  // Convert the time difference to total minutes
  let diffInMinutes: number = milliseconds / (1000 * 60); // 1000 ms * 60 seconds = 60,000 ms in 1 minute
  // Calculate hours and remaining minutes
  let hours: number = Math.floor(diffInMinutes / 60); // Get full hours
  return hours;
}
export function millisecondsToMinutes(milliseconds: number) {
  // Convert the time difference to total minutes
  let diffInMinutes: number = milliseconds / (1000 * 60); // 1000 ms * 60 seconds = 60,000 ms in 1 minute
  // Calculate hours and remaining minutes
  let minutes: number = Math.floor(diffInMinutes % 60); // Get the remaining minutes
  return minutes;
}

export function dateToReactStrapDateString(date: Date) {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: "America/New_York", // EST or EDT (depending on daylight savings)
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  // Split the formatted date and rearrange it into yyyy-mm-dd format
  let dateString = date.toLocaleString("en-US", dateOptions);
  let [month, day, year] = dateString.split(/[^0-9]+/); // Split by non-numeric characters
  let formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
