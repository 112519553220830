export const snowRoutes = [
  "1-01P",
  "1-02R",
  "1-03P",
  "1-04R",
  "1-05P",
  "1-06R",
  "1-07R",
  "1-08P",
  "1-09R",
  "1-10P",
  "1-10P",
  "1-11R",
  "1-12R",
  "1-13P",
  "1-14R",
  "2-01P",
  "2-02R",
  "2-03R",
  "2-04R",
  "2-05P",
  "2-05P",
  "2-06R",
  "2-07R",
  "2-08P",
  "2-09R",
  "2-10R",
  "2-12P",
  "2-12P",
  "2-13R",
  "2-15P",
  "3-01P",
  "3-02R",
  "3-03R",
  "3-03R",
  "3-04P",
  "3-05R",
  "3-06R",
  "3-07R",
  "3-08P",
  "3-09P",
  "3-10R",
  "3-11R",
  "3-12P",
  "3-13R",
  "3-14R",
  "4-01P",
  "4-02R",
  "4-03R",
  "4-04P",
  "4-05R",
  "4-06R",
  "4-07P",
  "4-08R",
  "4-09P",
  "4-10R",
  "4-11P",
  "4-12R",
  "4-13P",
  "4-14P",
  "4-15P",
  "4-16P",
  "4-17P",
  "4-18R",
  "4-19P",
  "4-20R",
  "4-21R",
  "5-01P",
  "5-02R",
  "5-03R",
  "5-04P",
  "5-05R",
  "5-06R",
  "5-07R",
  "5-09R",
  "5-10P",
  "5-11R",
  "5-12P",
  "5-13R",
  "5-16R",
];
