import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  GlobalSignOutCommand,
  AuthenticationResultType,
  RespondToAuthChallengeCommand,
  ConfirmForgotPasswordCommand,
  ChangePasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import CryptoJS from "crypto-js";
import config from "../config.json";
import { GlobalSignOutCommandInput } from "@aws-sdk/client-cognito-identity-provider";

export default class AuthService {
  private readonly cognitoClient: CognitoIdentityProviderClient;
  private isAuthenticated: boolean = false;
  private session: AuthenticationResultType | null = null;
  private password_session: any;

  constructor() {
    this.cognitoClient = new CognitoIdentityProviderClient({
      region: config.region,
    });
    // Check session on initialization
    this.isAuthenticated = !!sessionStorage.getItem('idToken');
  }

  isUserAuthenticated() {
    return !!sessionStorage.getItem('idToken');
  }

  refresh = async(refreshToken: string) =>{
    try{
      const command = new InitiateAuthCommand({
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: config.clientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken
        }
      });
      const {AuthenticationResult} = await this.cognitoClient.send(command);
      if(AuthenticationResult){
        this.session = AuthenticationResult;
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
        sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
        sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
        this.isAuthenticated = true;
        return AuthenticationResult;
      }
    }catch (error) {
      console.error("Error signing in: ", error);
      throw error;
    }
    
  }
  newPassword = async (username: string, password:string) => {
    try{
      const command = new RespondToAuthChallengeCommand({
        ChallengeName: "NEW_PASSWORD_REQUIRED",
        ClientId: config.clientId,
        Session: this.password_session,
        ChallengeResponses: {NEW_PASSWORD: password, USERNAME: username}
      })
      const {AuthenticationResult} =  await this.cognitoClient.send(command);
      if (AuthenticationResult) {
        this.session = AuthenticationResult;
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
        sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
        sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
        this.isAuthenticated = true;
        return AuthenticationResult;
      }
    }
    catch (error) {
      console.error("Error Changing Password: ", error);
      throw error;
    }
  };

  resetPassword = async (username: string, password: string, resetCode: string) =>{
    try{
      const command = new ConfirmForgotPasswordCommand({
        ClientId: config.clientId,
        ConfirmationCode: resetCode,
        Username: username,
        Password: password,
      });
      const status = await this.cognitoClient.send(command);
      if(status){
        console.log(status);
        return status;
      }
    }catch (error){
      console.error("Error resetting password", error);
      throw error;
    }
  }
  //TODO: implement this in frontend, so user can change password without our input
  changePassword = async (oldPassword: string, newPassword: string)=>{
    if(sessionStorage.getItem("accessToken") !== null){
      try{
        const command = new ChangePasswordCommand({
          AccessToken:sessionStorage.getItem("accessToken")!,
          PreviousPassword: oldPassword,
          ProposedPassword: newPassword
        })
        const status = await this.cognitoClient.send(command);
        if(status){
          console.log(status);
          return status;
        }
      }catch(error){
        console.error("Error changing password", error);
        throw error;
      }
    } 
  }

  signIn = async (username: string, password: string) => {
    try {
      const command = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: config.clientId,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          //SECRET_HASH: this.generateSecretHash(username),
        },
      });

      const { AuthenticationResult, ChallengeName, Session } = await this.cognitoClient.send(command);
      if (AuthenticationResult) {
        this.session = AuthenticationResult;
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
        sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
        sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
        this.isAuthenticated = true;
        return AuthenticationResult;
      }
      else if(ChallengeName==="NEW_PASSWORD_REQUIRED"){
        this.password_session = Session;
        throw(ChallengeName)
      }
    } catch (error) {
      console.error("Error signing in: ", error);
      throw error;
    }
  };

  signOut = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      if (accessToken) {
        const input: GlobalSignOutCommandInput = {
          AccessToken: accessToken
        };
        const command = new GlobalSignOutCommand(input);
        await this.cognitoClient.send(command);
      }
    } catch (error) {
      console.error(error);
    } finally {
      sessionStorage.clear();
      this.isAuthenticated = false;
    }
  };

  signUp = async (username: string, email: string, password: string) => {
    const params = {
      ClientId: config.clientId,
      Username: username,
      Password: password,
      SecretHash: this.generateSecretHash(username),
      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
      ],
    };
    try {
      const command = new SignUpCommand(params);
      const response = await this.cognitoClient.send(command);
      console.log("Sign up success: ", response);
      return response;
    } catch (error) {
      console.error("Error signing up: ", error);
      throw error;
    }
  };

  confirmSignUp = async (username: string, code: string) => {
    const params = {
      ClientId: config.clientId,
      Username: username,
      ConfirmationCode: code,
      SecretHash: this.generateSecretHash(username),
    };
    try {
      const command = new ConfirmSignUpCommand(params);
      await this.cognitoClient.send(command);
      console.log("User confirmed successfully");
      return true;
    } catch (error) {
      console.error("Error confirming sign up: ", error);
      throw error;
    }
  };

  private generateSecretHash = (username: string) => {
    const secretKey = config.clientSecret;
    const message = username + config.clientId;

    // Generate HMAC SHA-256
    const hash = CryptoJS.HmacSHA256(message, secretKey);

    // Base64 encode the hash
    const base64EncodedHash = hash.toString(CryptoJS.enc.Base64);
    return base64EncodedHash;
  };
}
export const authService = new AuthService();
