import { useEffect, useState } from "react";
import {
  CloseButton,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { ApiService } from "src/services/ApiService";
import {
  REDUCER_ACTION_TYPE,
  useViewModal,
  useViewModalDispatch,
} from "src/contexts/ViewModalContext";
import { FullInspection } from "src/types/FullInspection";
import { exampleData } from "../../../../types/FullInspection";
import { InspectionCard } from "./InspectionCard/InspectionCard";
import { PhotoCard } from "./PhotoCard/PhotoCard";

//TO DO
//how to have photo card and Inspection card always have the same size
type ViewModalProps = {
  showViewModal: boolean;
  toggleViewModal: Function;
};
export default function ViewModal(props: ViewModalProps) {
  const { toggleViewModal, showViewModal } = props;
  const viewModalContext = useViewModal();
  const viewModalDispatch = useViewModalDispatch();
  const [data, setData] = useState<FullInspection>(exampleData);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    async function getData() {
      try {
        const response = await ApiService.get(
          `/inspection/${viewModalContext.inspection_id}`
        );
        const resInspection: FullInspection = response.data;
        viewModalDispatch({
          actionType: REDUCER_ACTION_TYPE.SET_INSPECTION,
          payload: {
            fullRecord: {
              ...resInspection,
              assignment:
                resInspection.assignment === null
                  ? null
                  : {
                      ...resInspection.assignment,
                      time_in:
                        resInspection.assignment.time_in != null
                          ? new Date(resInspection.assignment.time_in)
                          : null,
                      time_out:
                        resInspection.assignment.time_out != null
                          ? new Date(resInspection.assignment.time_out)
                          : null,
                      driver_name: resInspection.inspection.driver_name,
                      driver_phone: resInspection.inspection.driver_phone,
                    },
            },
            inspectionId: resInspection.inspection.id,
          },
        });
        setData(resInspection);
        console.log(resInspection);
      } catch (error) {
        console.error(error);
        setData(exampleData);
      }
    }
    getData();
  }, [viewModalContext.inspection_id, viewModalDispatch]);
  const toggle = () => {
    return toggleViewModal();
  };
  return (
    <Modal
      isOpen={showViewModal}
      toggle={toggle}
      centered
      size="lg"
      style={{
        minWidth: "80%",
      }}
    >
      <Nav tabs style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <NavItem>
            <NavLink
              active={activeTab === 1 ? true : false}
              onClick={() => {
                setActiveTab(1);
              }}
            >
              Inspection and Assignment Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 2 ? true : false}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              Photo
            </NavLink>
          </NavItem>
        </div>
        <NavItem style={{ paddingTop: "0.5%", paddingRight: "0.5%" }}>
          <CloseButton onClick={toggle}></CloseButton>
        </NavItem>
      </Nav>
      <ModalBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <InspectionCard data={data} />
          </TabPane>
          <TabPane tabId={2}>
            <PhotoCard url={data.inspection?.photoUrl} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}
