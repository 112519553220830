import { Container, Card, CardHeader, CardBody } from "reactstrap";
import AssignmentCard from "../AssignmentCard/AssignmentCard";
import { FullInspection } from "src/types/FullInspection";

type InspectionCardProps = {
  data: FullInspection;
};
export function InspectionCard(props: InspectionCardProps) {
  const { data } = props;
  return (
    <Container fluid style={{ display: "flex" }}>
      <Container>
        <Card>
          <CardHeader className="text-center">
            Detailed Inspection Report
          </CardHeader>
          <CardBody>
            <div>
              <p>Inspector: {data?.inspectorContactInfo.inspector_name}</p>
              <p>Inspector Email: {data?.inspectorContactInfo.email}</p>
              <p>Shift-Type: {data?.inspection.shift_type}</p>
              <p>Vehicle VIN: {data?.inspection.vehicle_vin}</p>
              <p>Vehicle Tag: {data?.inspection.vehicle_tag}</p>
              <p>AVL #: {data?.vehicles.avl}</p>
              <p>Inspection Date: {data?.inspection.inspected_on}</p>
              <br />
              <h6>
                <strong>Results</strong>
              </h6>
              <hr />
              <p>DPWT: {data?.inspection.inspec_result.dpwt?.toString()}</p>
              <p>AVL: {data?.inspection.inspec_result.avl?.toString()}</p>
              <p>Lights: {data?.inspection.inspec_result.lights?.toString()}</p>
              <p>Plow: {data?.inspection.inspec_result.plow?.toString()}</p>
              <p>
                Spreader: {data?.inspection.inspec_result.spreader?.toString()}
              </p>
              <p>
                Spreader-Setting:{" "}
                {data?.inspection.inspec_result.spreader_setting?.toString()}
              </p>

              <p
                style={{
                  color: data?.inspection.inspec_result.pass ? "green" : "red",
                }}
              >
                Determination: {data?.inspection.inspec_result.pass?.toString()}
              </p>
              <p>Comments: {data?.inspection.inspec_result.comments}</p>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader className="text-center">
            Contractor Information
          </CardHeader>
          <CardBody
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{}}>
              <h6>
                <strong>{data?.contractor.company_name}</strong>
              </h6>
              <p>
                {data?.contractor.street}, {data?.contractor.city},
                <div>
                  {data?.contractor.address_state},{" "}
                  {data?.contractor.postal_code}
                </div>
              </p>
            </div>
            <div style={{}}>
              <h6>
                <strong>Contact</strong>
              </h6>
              <hr />
              <p>Name: {data?.contractorContactInfo.contact_name}</p>
              <p>Email: {data?.contractorContactInfo.contact_email}</p>
              <p>Cell Number: {data?.contractorContactInfo.contact_cell}</p>
              <p>Office Number: {data?.contractorContactInfo.contact_office}</p>
            </div>
          </CardBody>
        </Card>
        <br />
        <AssignmentCard />
      </Container>
    </Container>
  );
}
