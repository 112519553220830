import { useState, useEffect } from "react";
// import DistrictFilter from "./components/DistrictFilter/DistrictFilter";
import Table from "./components/Table/Table";
import "react-datepicker/dist/react-datepicker.css";
//import { ApiService } from "src/services/ApiService";
import EditModal from "./components/EditModal/EditModal";
import ViewModal from "./components/ViewModal/ViewModal";
// import exampleTableData from "./data/exampleTableData.json";
import {
  Label,
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  Alert,
} from "reactstrap";
import { Shifts } from "src/types/Shifts";
import { dateToReactStrapDateString } from "src/utils/convert";
import axios from "axios";

export default function DashboardPage() {
  //component handling state
  const [isLoading, setIsLoading] = useState(true);
  const [noDataAlertVisible, setNoDataAlertVisible] = useState(false);
  const [tableData, setTableData] = useState<Shifts>([]);
  const [data, setData] = useState<Shifts>([]);
  const [startDate, setStartDate] = useState(
    dateToReactStrapDateString(new Date(Date.now() - 12096e5))
  ); //start date from 2 weeks ago
  const [endDate, setEndDate] = useState(
    dateToReactStrapDateString(new Date())
  );

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);

  const [shiftFilter, setShiftFilter] = useState<string[]>([]);
  
  //revisit
  useEffect(() => {
    setIsLoading(false);
  }, [tableData]);

  //Table Data based on shift filter
  useEffect(() => {
    if (shiftFilter.length <= 0) {
      setTableData(data);
      return;
    }
    //filter data for table
    const filtered = data.filter((shift) =>
      shiftFilter.includes(shift.shift_type)
    );
    setTableData(filtered);
  }, [data, shiftFilter]);

  function toggleEditModal() {
    setShowEditModal((prev) => !prev);
  }
  function toggleViewModal() {
    setShowViewModal((prev) => !prev);
  }

  function onShiftFilterBtnClick(selected: string) {
    const index = shiftFilter.indexOf(selected);
    if (index < 0) {
      shiftFilter.push(selected);
    } else {
      shiftFilter.splice(index, 1);
    }
    setShiftFilter([...shiftFilter]);
  }

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  async function handleSearch() {
    try {
      const endpoint = axios.create({
        baseURL: "https://wfg4c0t0m2.execute-api.us-east-1.amazonaws.com/Dev/api/v1",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("idToken"),
        },
      });
      const { data } = await endpoint.get("/inspections", {
        params: { startDate, endDate },
      });
      console.log("data", data, startDate, endDate);
      //no data
      if (data === null || typeof data === "undefined" || data.length <= 0) {
        setNoDataAlertVisible(true);
        setData([]);
        return;
      }
      setData(data);
      setNoDataAlertVisible(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section id="main-content">
      <h6>
        Prince George's County Departament of Public Works & Transportation
      </h6>
      <EditModal
        showEditModal={showEditModal}
        toggleEditModal={toggleEditModal}
      />
      <ViewModal
        showViewModal={showViewModal}
        toggleViewModal={toggleViewModal}
      />
      <div className="block">
        <div className="filter va">
          <div className="va filter-item">
            <Label>Select Date Range:</Label>
            <InputGroup>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              ></Input>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              ></Input>
              <Button color="warning" onClick={handleSearch}>
                Search
              </Button>
            </InputGroup>
          </div>
          <div
            className="filter-item"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Label for="shiftsFilter">Filter Shifts:</Label>
            <ButtonGroup size="md" id="shiftsFilter">
              <Button
                color="warning"
                outline
                onClick={() => onShiftFilterBtnClick("YELLOW")}
                active={shiftFilter.includes("YELLOW")}
              >
                Yellow
              </Button>
              <Button
                color="warning"
                outline
                onClick={() => onShiftFilterBtnClick("ORANGE")}
                active={shiftFilter.includes("ORANGE")}
              >
                Orange
              </Button>
            </ButtonGroup>
          </div>
          {noDataAlertVisible ? (
            <Alert color="danger">No Data For Selected Date Range.</Alert>
          ) : (
            <></>
          )}
          {/* for Admin & District Admins <DistrictFilter /> */}
          {isLoading ? (
            <h1>Loading...</h1>
          ) : (
            <Table
              tableData={tableData}
              toggleEditModal={toggleEditModal}
              toggleViewModal={toggleViewModal}
            />
          )}
        </div>
      </div>
    </section>
  );
}
